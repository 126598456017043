// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-templates-av-news-template-js": () => import("./../src/templates/av-news-template.js" /* webpackChunkName: "component---src-templates-av-news-template-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-connected-car-news-js": () => import("./../src/templates/connected-car-news.js" /* webpackChunkName: "component---src-templates-connected-car-news-js" */),
  "component---src-templates-image-recognition-news-js": () => import("./../src/templates/image-recognition-news.js" /* webpackChunkName: "component---src-templates-image-recognition-news-js" */),
  "component---src-templates-truck-news-js": () => import("./../src/templates/truck-news.js" /* webpackChunkName: "component---src-templates-truck-news-js" */)
}

